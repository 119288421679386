.MuiDialog-root {
  .MuiDialog-container {
    .MuiDialog-paper {
      .modal-title {
        .MuiTypography-root {
          font-family: var(--headers-font-family) !important;
          font-size: 28px;
          color: #ffffff;
          font-weight: normal;
          text-transform: capitalize;
          font-family: 'Roboto Condensed', sans-serif !important;
          font-weight: bold;
        }
      }

      .MuiDialogTitle-root {
        background: var(--theme1-bg);
        color: var(--primary-color);
        padding: 5px 15px; 
        text-align: center; 
        height: 60px; 
        display: flex; 
        align-items: center; 
        justify-content: center; 
        color: #ffffff;
        font-weight: bold;
        text-transform: capitalize;

        .MuiTypography-root {
          color: var(--ion-text-light);
          font-weight: 600;
          text-transform: capitalize !important;
          font-family: 'Roboto Condensed', sans-serif !important;
          font-size: 22px; 
          vertical-align: middle;
          margin-top: 0; 
        }
      }

      .MuiIconButton-root {
        position: absolute;
        top: 10px; 
        right: 10px;
        opacity: 1;
        color: var(--theme1-bg);
      }

      .light-bg-title {
        background: var(--ion-background-secondary);
        height: 60px;
        width: 1200px;
        max-width: 100%; 
        align-items: center;
        display: flex; 
        justify-content: center;

        .MuiTypography-root {
          color: #fff;
          font-weight: normal;
          text-transform: capitalize !important;
        }
      }

      .MuiDialogContent-root {
        background: var(--primary-color);
        padding: 15px 20px; 
        max-height: calc(100vh - 120px); 
        overflow-y: auto; 

        .MuiButton-containedPrimary {
          background-color: var(--ion-color-primary);
          color: var(--ion-color-primary-contras);
          text-transform: none;
          font-weight: 600;
        }

        .MuiButton-containedPrimary:hover {
          background-color: var(--ion-color-primary-shade);
        }
      }

      .MuiDialogActions-root {
        justify-content: center !important;

        @media screen and (max-width: 720px) {
          padding: 20px 0 0 0;
        }
      }
    }
  }
}

@media screen and (max-width: 720px) {
  .MuiDialog-root .MuiDialog-container .MuiDialog-paper {
    width: 95%; 
    margin: 10px auto; 
    border-radius: 10px;

    .MuiDialogTitle-root {
      height: 50px; 
      font-size: 18px; 
      padding: 10px; 
    }

    .MuiDialogContent-root {
      padding: 10px 15px; 
    }
  }

  .modal-close-btn .MuiIconButton-label svg {
    // fill: #000 !important;
    color: white !important;

  }
}

.MuiDialog-paperWidthMd {
  width: 100%;
  max-width: 1185px; 
}

.popover-content.sc-ion-popover-ios {
  border-radius: 2px !important;
  width: 190px;
  right: 33px;
  height: auto;
  left: auto !important;
}
